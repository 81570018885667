
import { defineComponent } from "vue";
import Area02 from "@/components/widgets/charts/Area02.vue";

import Papa from "papaparse";

import axios from "axios";
import List from "@/data/List.json";
import List2 from "@/data/List2.json";

// import axios from "axios";
// import VueAxios from "vue-axios";

// import List2 from "@/data/List.json";

export default defineComponent({
  name: "kt-widget-12",
  components: { Area02 },
  props: {
    widgetClasses: String,
  },
  data(): {
    Data: any;
    Data2: any;
    Data3: any;
    Data4: any;
    ProductType: string;
    DataNumber: string;
    List: any;
    initList: any;
    ViewList: any;
    List2: any;
    initList2: any;
    ViewList2: any;
    pageSize: any;
    totalPage: any;
    totalPage2: any;
    Type1: any;
    Type2: any;
    Num: any;
    Step: any;
    Keyword: any;
    Name: any;
  } {
    return {
      Data: [],
      Data2: [],
      Data3: [],
      Data4: [],
      ProductType: "",
      DataNumber: "",
      List: List,
      initList: [],
      ViewList: [],
      initList2: [],
      List2: List2,
      ViewList2: [],
      pageSize: 1,
      totalPage: 1,
      totalPage2: 1,
      Type1: "",
      Type2: "",
      Num: "",
      Step: 1,
      Keyword: "",
      Name: "",
    };
  },
  computed: {},
  watch: {
    pageSize(newVal) {
      console.log(newVal);
    },
    Keyword(newVal) {
      this.InitList(newVal);
    },
  },
  // setup() {},
  methods: {
    Array(type, page) {
      this.pageSize = page;
      if (type == 0) {
        this.ViewList = this.List.filter((item, index) => {
          return (
            index < this.pageSize * 10 && (this.pageSize - 1) * 10 <= index
          );
        });
      } else {
        this.ViewList2 = this.List2.filter((item, index) => {
          return (
            index < this.pageSize * 10 && (this.pageSize - 1) * 10 <= index
          );
        });
      }
    },
    async handleFileChange(event) {
      const file = event.target.files[0];
      await Papa.parse(file, {
        complete: results => {
          for (let i = 0; i < results.data.length; i++) {
            let obj = {};
            if (i !== 0) {
              for (let a = 0; a < results.data[i].length; a++) {
                let data = "data" + a;
                obj[data] = results.data[i][a];
                if (a == results.data[i].length - 1) {
                  this.List2.push(obj);
                  this.initList2.push(obj);
                }
              }
            }
          }
          this.Array(1, this.pageSize);
          this.totalPage2 = Math.trunc(this.List2.length / 10);
        },
      });
    },
    async handleFileChange2(event) {
      const file = event.target.files[0];
      await Papa.parse(file, {
        complete: results => {
          for (let i = 0; i < results.data.length; i++) {
            let obj = {};
            if (i !== 0) {
              for (let a = 0; a < results.data[i].length; a++) {
                let data = "data" + a;
                obj[data] = results.data[i][a];
                if (a == results.data[i].length - 1) {
                  this.List.push(obj);
                  this.initList.push(obj);
                }
              }
            }
          }
          this.Array(0, this.pageSize);
          this.totalPage = Math.trunc(this.List.length / 10);
        },
      });
    },

    PageSwitch(type, page) {
      this.Array(type, page);
    },
    Switch() {
      this.pageSize == 1;
      // this.Array(type);
    },
    Chart(data, machine) {
      let arr;
      // Init Data
      (this.Data = [
        {
          name: "實際產能",
          type: "column",
          data: [],
        },
        {
          name: "標準產能",
          type: "area",
          // type: "line",
          data: [],
        },
      ]),
        // Init Data2
        (this.Data2 = {
          chart: {
            type: "line",
          },
          stroke: {
            // curve: "smooth",
            width: [0, 4],
          },
          title: {
            text: "依日期檢視",
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1],
          },
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          colors: ["#775DD0", "#546E7A", "#26a69a", "#D10CE8"],
          plotOptions: {
            bar: {
              // colors: {},
              columnWidth: "50%",
            },
          },
          labels: [],

          xaxis: {
            // type: "datetime",
          },
          yaxis: {
            title: {
              text: "產能",
            },
            min: 0,
            // max: 160,
            tickAmount: 6,
          },
        });
      // Init Data3
      (this.Data3 = [
        {
          name: "實際產能",
          type: "column",
          data: [],
        },
        { name: "標準產能", type: "area", data: [] },
      ]),
        // Init Data4
        (this.Data4 = {
          chart: {
            type: "line",
          },
          stroke: {
            // curve: "smooth",
            width: [0, 4],
          },
          title: {
            text: "依日期檢視",
          },
          fill: {
            opacity: [0.85, 0.25, 1],
            gradient: {
              inverseColors: false,
              shade: "light",
              type: "vertical",
              opacityFrom: 0.85,
              opacityTo: 0.55,
              stops: [0, 100, 100, 100],
            },
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [0, 1],
          },
          // colors: ["#775DD0", "#546E7A", "#26a69a", "#D10CE8"],
          plotOptions: {
            bar: {
              // colors: {},
              columnWidth: "50%",
            },
          },
          labels: [],

          xaxis: {
            // type: "datetime",
          },
          yaxis: {
            title: {
              text: "產能",
            },
            min: 0,
            max: 160,
            tickAmount: 6,
          },
        });
      //
      this.Name = data[0].data4;
      for (let i = 0; i < data.length; i++) {
        this.Data[0].data.push(data[i].data8);
        this.Data[1].data.push(data[i].data9);
        this.Data2.labels.push(data[i].data0.slice(0, -6));
      }

      machine = Object.values(
        machine.reduce((result: any, currentValue: any) => {
          (result[currentValue["data2"]] =
            result[currentValue["data2"]] || []).push(currentValue);
          return result;
        }, {})
      );
      //
      let a = 0;
      let b = 0;
      for (let i = 0; i < machine.length; i++) {
        for (let z = 0; z < machine[i].length; z++) {
          a = a + parseInt(machine[i][z].data6);
          b = b + parseInt(machine[i][z].data7);
          if (z == machine[i].length - 1) {
            // this.Data3[0].data.push();
            // this.Data3.push({
            //   name: machine[i][z].data2,
            //   type: "column",
            //   data: [Math.trunc(a / b)],
            // });
            this.Data4.labels.push(machine[i][z].data2);
            this.Data3[1].data.push(parseInt(machine[i][z].data9));
            this.Data3[0].data.push(Math.trunc(a / b));
          }
        }
        // a = a + parseInt(machine[i].data6);
        // b = b + parseInt(machine[i].data7);
        // if (i == data.length - 1) {
        //   this.Data3[0].data.push(Math.trunc(a / b));
        //   this.Data3[1].data.push(parseInt(data[i].data9));
        // }
        // this.Data3[0].data.push(parseInt(data[i].data8));
      }
    },
    Init() {
      this.Data = [];
      this.Data2 = [];
      this.Data3 = [];
      this.Data4 = [];
    },
    Search() {
      this.pageSize == 1;
      this.List = this.initList;
      if (this.Num != "") {
        if (this.Type1 == 0) {
          if (this.Type2 == 0) {
            this.List = this.List.filter((item, index) => {
              return Number(item.data10) >= Number(this.Num);
            });
          } else {
            this.List = this.List.filter((item, index) => {
              return Number(item.data10) <= Number(this.Num);
            });
          }
        } else {
          if (this.Type2 == 0) {
            this.List = this.List.filter((item, index) => {
              return Number(item.data11) >= Number(this.Num);
            });
          } else {
            this.List = this.List.filter((item, index) => {
              return Number(item.data11) <= Number(this.Num);
            });
          }
        }
      }
      this.ViewList = this.List.filter((item, index) => {
        return index < this.pageSize * 10 && (this.pageSize - 1) * 10 <= index;
      });
      this.totalPage = Math.trunc(this.List.length / 10);
    },
    async Sort(val, type) {
      if (type == 0) {
        this.List = this.List.sort(function (a, b) {
          return a.data11 - b.data11;
        });
      } else {
        this.List = this.List.sort(function (a, b) {
          return b.data11 - a.data11;
        });
      }
      setTimeout(() => {
        this.ViewList = this.List.filter((item, index) => {
          return (
            index < this.pageSize * 10 && (this.pageSize - 1) * 10 <= index
          );
        });
      }, 1000);
    },
    InitList(val) {
      var arr;
      var b = 0;
      var c = 0;
      var d = 0;
      var e = 0;
      var arr2: any[] = [];
      var arr3: any[] = [];
      arr = Object.values(
        List2.reduce((result: any, currentValue: any) => {
          (result[currentValue["data4"]] =
            result[currentValue["data4"]] || []).push(currentValue);
          return result;
        }, {})
      );

      for (let i = 0; i < arr.length; i++) {
        for (let a = 0; a < arr[i].length; a++) {
          b = b + parseInt(arr[i][a].data6);
          c = c + parseInt(arr[i][a].data7);
          d = d + parseInt(arr[i][a].data9);
          e = e + parseInt(arr[i][a].data10);
          arr3.push(Object.assign({}, arr[i][a]));
          if (a == arr[i].length - 1) {
            arr[i][a].machine = arr3;
            arr[i][a].time = arr3;
            arr[i][a].data6 = b;
            arr[i][a].data7 = c;
            arr[i][a].data8 = Math.trunc(b / c);
            arr[i][a].data9 = Math.trunc(d / (a + 1));
            arr[i][a].data10 = Math.trunc(e / (a + 1));
            b = 0;
            c = 0;
            d = 0;
            e = 0;
            arr2.push(arr[i][a]);
            arr3 = [];
          }
        }
      }
      var arr4: any[] = [];
      if (val != "") {
        for (var i = 0; i < arr2.length; i++) {
          if (arr2[i].data4.indexOf(val.toUpperCase()) >= 0) {
            arr4.push(arr2[i]);
          }
        }
        this.List2 = arr4;
      } else {
        this.List2 = arr2;
      }
      // if(val!=''){
      // this.List2
      // }
      // else{
      // }

      //
      this.Array(0, this.pageSize);
      this.totalPage = Math.ceil(this.List.length / 10);
      //
      this.Array(1, this.pageSize);
      this.totalPage2 = Math.ceil(this.List2.length / 10);
    },
  },
  mounted() {
    this.InitList("");
  },
});
