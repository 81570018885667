
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
// import { object } from "yup/lib/locale";
export default defineComponent({
  name: "widget-1",
  props: {
    widgetClasses: String,
    Name: String,
    Data: [Object],
    Data2: [Object],
    Data3: [Object],
    Data4: [Object],
  },
  data(): {
    // series: Array<any>;
    // options: any;
    // series1: Array<any>;
    // options1: any;
    // series2: Array<any>;
    // options2: any;
    // series3: Array<any>;
    // options3: any;
  } {
    return {};
  },
  components: {},
  setup() {
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const baseColor = getCSSVariableValue("--bs-primary");
    const secondaryColor = getCSSVariableValue("--bs-gray-600");
    const dangerColor = getCSSVariableValue("--bs-danger");
  },
  methods: {
    Type(index) {
      switch (index) {
        case 1:
          break;
        case 2:
          break;
        case 3:
          break;
      }
    },
  },
});
