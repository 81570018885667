import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert alert-primary" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "mb-1 text-dark" }
const _hoisted_4 = { class: "nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = {
  class: "tab-content",
  id: "myTabContent"
}
const _hoisted_8 = {
  class: "tab-pane fade show active",
  id: "kt_customer_view_overview_tab",
  role: "tabpanel"
}
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = {
  class: "tab-pane fade",
  id: "kt_tab_03",
  role: "tabpanel"
}
const _hoisted_11 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h4", _hoisted_3, "料貨號編號：" + _toDisplayString(_ctx.Name), 1)
      ])
    ]),
    _createElementVNode("ul", _hoisted_4, [
      _createElementVNode("li", _hoisted_5, [
        _createElementVNode("a", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Type(1))),
          class: "nav-link text-active-primary pb-4 active",
          "data-bs-toggle": "tab",
          href: "#kt_customer_view_overview_tab"
        }, "依時間")
      ]),
      _createElementVNode("li", _hoisted_6, [
        _createElementVNode("a", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Type(3))),
          class: "nav-link text-active-primary pb-4",
          "data-bs-toggle": "tab",
          href: "#kt_tab_03"
        }, "依機台")
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", {
          class: _normalizeClass(["card", _ctx.widgetClasses])
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_apexchart, {
              type: "line",
              height: "480",
              series: _ctx.Data,
              options: _ctx.Data2
            }, null, 8, ["series", "options"])
          ])
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", {
          class: _normalizeClass(["card", _ctx.widgetClasses])
        }, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_apexchart, {
              series: _ctx.Data3,
              options: _ctx.Data4,
              height: "480"
            }, null, 8, ["series", "options"])
          ])
        ], 2)
      ])
    ])
  ], 64))
}